import { AddIcon } from "@chakra-ui/icons";
import { Box, VStack, Textarea, Input, IconButton } from "@chakra-ui/react";
import { borderRadius } from "@mui/system";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import styled from "styled-components";
import newStockAdd from "../logic/newStockAdd";

import MyFace from "./MyFace";

const AddIconDiv = styled.span`
  position: absolute;
  bottom: 50px;
  right: 0;
  background-color: blue;
`;

const NewNote = (props) => {
  const { user } = props;

  const [typing, setTyping] = useState(false);

  /* ↓state変数を定義 */
  const [StockTitle, setStockTitle] = useState("");
  const [StockDesc, setStockDesc] = useState("");
  const [StockFace, setStockFace] = useState("");

  const [textareaStyle, setTextareaStyle] = useState({
    textAlign: "justfiy",
  });

  const newnoteadded = async (e) => {
    e.preventDefault();
    newStockAdd(StockTitle, StockDesc, StockFace, user);

    setStockTitle("");
    setStockDesc("");
    setStockFace("");
  };

  function textareaHeight(e) {
    setTextareaStyle({
      height: e.target.scrollHeight + "px",
      textAlign: "justfiy",
      maxHeight: "500px",
    });
  }

  function contentChanged(e) {
    setTyping(true);
    setStockDesc(e.target.value);
  }

  return (
    <Box
      w="85%"
      margin="46px auto"
      border="1px solid #e0e0e0"
      padding="6"
      borderRadius="3px"
      boxShadow="0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%)"
    >
      <VStack align="stretch" position="relative">
        {typing ? (
          <Textarea
            name="title"
            type="note"
            value={StockTitle}
            onChange={(e) => setStockTitle(e.target.value)}
            fontSize={["md", "", "xxl"]}
            fontWeight={"bold"}
            placeholder="今日一番印象に残ったことは？"
            variant="unstyled"
          />
        ) : null}

        <Textarea
          name="description"
          type="text"
          value={StockDesc}
          onClick={contentChanged}
          onChange={contentChanged}
          style={textareaStyle}
          onInput={textareaHeight}
          variant="unstyled"
          placeholder="Stockしてみよう"
          className="materialize-textarea"
        ></Textarea>
        {typing ? (
          <>
            <MyFace setStockFace={setStockFace} />
            <IconButton
              colorScheme="blue"
              aria-label="Search database"
              onClick={newnoteadded}
              size="lg"
              icon={<AddIcon />}
              style={{
                position: "absolute",
                bottom: "-45px",
                right: "0",
                borderRadius: "50%",
              }}
            />
          </>
        ) : null}
      </VStack>
    </Box>
  );
};

export default NewNote;
