import { onAuthStateChanged, signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { FirebaseAuth } from "./FirebaseConfig";
import { setUser } from "firebase/auth";
import { Navigate } from "react-router-dom";

export const FirebaseAuthFunc = () => {
  /* ↓ログインを判定する設定 */
  const [user, setUser] = useState();

  useEffect(() => {
    onAuthStateChanged(FirebaseAuth, (currentUser) => {
      setUser(currentUser);
    });
  });
};

/* ↓関数「logout」を定義 */
export const logout = async () => {
  await signOut(FirebaseAuth);
  Navigate("/login/");
};
