import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { FirestoreDB } from "../firebase/FirebaseConfig";
import firebase from "firebase/app";

const newStockAdd = (title, description, face, user, date) => {
  if (user) {
    const stocksRef = collection(FirestoreDB, "users", user?.uid, "stocks");
    if (!date) {
      setDoc(doc(stocksRef), {
        title: title,
        description: description,
        face: face ? face : "",
        timestamp: serverTimestamp(),
      });
    } else {
      setDoc(doc(stocksRef), {
        title: title,
        description: description,
        face: face ? face : "",
        timestamp: new Date(date),
      });
    }
    console.log("success");
  } else {
    console.log("error");
  }
};
// const newnoteadded = async (e) => {
//   e.preventDefault();
//   if (user) {
//     const stocksRef = collection(FirestoreDB, "users", user?.uid, "stocks");
//     setDoc(doc(stocksRef), {
//       title: StockTitle,
//       description: StockDesc,
//       face: StockFace,
//       timestamp: serverTimestamp(),
//     });
//     console.log("success");
//   } else {
//     console.log("error");
//   }

//   setStockTitle("");
//   setStockDesc("");
//   setStockFace("");
// };

export default newStockAdd;
