import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import NewNote from "./sections/NewNote";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import createCard from "./sections/CreateCard";
import { FirebaseAuth, FirestoreDB } from "./firebase/FirebaseConfig";
import Header from "./sections/Header";
import { Flex } from "@chakra-ui/react";

const Mypage = () => {
  /* ↓state変数「user」を定義 */
  const [user, setUser] = useState("");
  /* ↓state変数「loading」を定義 */
  const [loading, setLoading] = useState(true);

  /* ↓ログインしているかどうかを判定する */
  useEffect(() => {
    onAuthStateChanged(FirebaseAuth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
  }, []);

  /* ↓関数「logout」を定義 */

  // データをDBから取得
  const [stocks, setStocks] = useState();

  useEffect(() => {
    if (user) {
      //データベースからデータを取得する
      const stocksRef = collection(FirestoreDB, "users", user?.uid, "stocks");

      const stocksQuery = query(stocksRef, orderBy("timestamp", "desc"));
      onSnapshot(stocksQuery, (stock) => {
        if (stock) {
          setStocks(stock.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        } else {
          console.log("error");
        }
      });
    } else {
      console.log("StockError");
    }
  }, [user]);

  // DBからユーザーデータを取得

  /* ↓state変数を定義 */
  const [StockTitle] = useState("");
  const [StockDesc] = useState("");
  const [StockFace] = useState("");

  const registerStock = async (e) => {
    e.preventDefault();
    const stocksRef = collection(FirestoreDB, "users", user?.uid, "stocks");

    setDoc(doc(stocksRef), {
      title: StockTitle,
      description: StockDesc,
      face: StockFace,
      // userid: user.uid,
      timestamp: serverTimestamp(),
    });
  };

  // ==========================

  function deleteNote(id) {
    deleteDoc(doc(FirestoreDB, "users", user?.uid, "stocks", id));
  }

  // ==========================

  return (
    <>
      {!loading && (
        <>
          {!user ? (
            <Navigate to={"/login/"} />
          ) : (
            <>
              <Header user={user} />
              <NewNote add={registerStock} user={user} />
              <Flex
                alignItems="baseline"
                width="90%"
                margin="24px auto"
                flexWrap="wrap"
                gap="4"
              >
                {stocks?.map((stock) => createCard(stock, deleteNote, user))}
              </Flex>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Mypage;
