import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Register from "./component/Register";
import Login from "./component/Login";
import Mypage from "./component/Mypage";
import Test from "./component/Test";
import Header from "./component/sections/Header";
import Footer from "./component/sections/Footer";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path={"/register/"} element={<Register />} />
          <Route path={"/login/"} element={<Login />} />
          <Route path={"/"} element={<Mypage />} />
          <Route path={"test"} element={<Test />} />
        </Routes>
      </BrowserRouter>
      <Footer />
      
    </ChakraProvider>
  );
}

export default App;
