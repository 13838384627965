import React from "react";

import {
  Box,
  Container,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import logo from "./peoplesLogo.png";

const Footer = () => {
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        spacing={4}
        justify={"center"}
        align={"center"}
      >
        <Image src={logo} width="100px" />
        <Stack direction={"row"} spacing={6}>
          <Link href="/">Home</Link>
          <Link href="https://yourverse.jp/" target="_blank">
            Yourverse
          </Link>
          <Link href="https://peoples-yv.jp/" target="_blank">
            People's
          </Link>
          <Link href="https://yourverse.jp/contact/">Contact</Link>
        </Stack>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
        >
          <Text>© 2022 YourVerse.Inc All rights reserved</Text>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
