import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FirebaseAuth, FirestoreDB } from "./firebase/FirebaseConfig";
import logo from "./sections/peoplesLogo.png";

const Register = () => {
  /* ↓state変数を定義 */
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerName, setRegisterName] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");

  // firestoreにuserを作成
  const newUserAdd = async (user, name) => {
    const userRef = collection(FirestoreDB, "users");
    try {
      setDoc(doc(userRef, user?.uid), {
        name: name,
        createdAt: serverTimestamp(),
      });
      console.log("success");
    } catch (error) {
      alert("DBには入りませんでした。してください");
    }
  };
  /* ↓関数「handleSubmit」を定義 */
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await createUserWithEmailAndPassword(
        FirebaseAuth,
        registerEmail,
        registerPassword
      );

      await signInWithEmailAndPassword(
        FirebaseAuth,
        registerEmail,
        registerPassword
      ).then((userCredential) => {
        // Signed in
        const userId = userCredential.user;
        newUserAdd(userId, registerName);
        alert("ユーザー追加完了しました。");
        setRegisterName("");
        setRegisterEmail("");
        setRegisterPassword("");
      });
    } catch (error) {
      alert("正しく入力してください");
    }
  };

  //パスワードの表示非表示を管理
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Flex minH={"90vh"} align={"center"} justify={"center"}>
        <Stack
          spacing={8}
          mx={"auto"}
          // maxW={["90%", "", "600px"]}
          minW={["98%", "", "600px"]}
          py={24}
          px={6}
        >
          <Stack align={"center"}>
            <Heading fontSize={"4xl"} textAlign={"center"}>
              <Image src={logo} width="200px" />
            </Heading>
          </Stack>
          <form onSubmit={handleSubmit}>
            <Box rounded={"lg"} boxShadow={"lg"} p={8}>
              <Stack spacing={4}>
                <Text textAlign={"center"} py={"3"} fontWeight={"bold"}>
                  新規登録
                </Text>
                <FormControl id="email" isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="email"
                    type="email"
                    value={registerEmail}
                    onChange={(e) => setRegisterEmail(e.target.value)}
                  />
                </FormControl>
                <FormControl id="name" isRequired>
                  <FormLabel>ユーザー名</FormLabel>
                  <Input
                    name="name"
                    type="text"
                    value={registerName}
                    onChange={(e) => setRegisterName(e.target.value)}
                  />
                </FormControl>
                <FormControl id="password" isRequired>
                  <FormLabel>パスワード</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={registerPassword}
                      onChange={(e) => setRegisterPassword(e.target.value)}
                    />
                    <InputRightElement h={"full"}>
                      <Button
                        variant={"ghost"}
                        onClick={() =>
                          setShowPassword((showPassword) => !showPassword)
                        }
                      >
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  <Text fontSize={"sm"} pt={"2"} color={"gray"}>
                    半角英数字8文字以上で登録願います。
                  </Text>
                </FormControl>
                <Stack spacing={10} pt={2}>
                  <Button
                    loadingText="Submitting"
                    size="lg"
                    type="submit"
                    bg={"blue.400"}
                    color={"white"}
                    my="4"
                    _hover={{
                      bg: "blue.500",
                    }}
                  >
                    登録
                  </Button>
                </Stack>
                {/* <Stack pt={6}>
                    <Text align={"center"}>
                      Already a user? <Link color={"blue.400"}>Login</Link>
                    </Text>
                  </Stack> */}
              </Stack>
            </Box>
          </form>
          <Text>
            ログインは<Link to={`/login/`}>こちら</Link>
          </Text>
        </Stack>
      </Flex>
    </>
  );
};

export default Register;
