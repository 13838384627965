import {
  Box,
  ButtonGroup,
  HStack,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import React from "react";
import styled from "styled-components";

const RadioCard = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        backgroundColor="gray.200"
        _checked={{
          bg: "teal.400",
          color: "white",
          borderColor: "teal.400",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={4}
        py={1}
        fontSize="2xl"
      >
        {props.children}
      </Box>
    </Box>
  );
};

const MyFace = (props) => {
  const { setStockFace } = props;
  const options = ["😨", "😡", "😢", "😄", "😌"];
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "Face",
    defaultValue: "",
    onChange: setStockFace,
  });

  const group = getRootProps();
  return (
    <HStack {...group} flexWrap={"wrap"} gap={["8px", "", "0"]}>
      {options.map((value) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} {...radio}>
            {value}
          </RadioCard>
        );
      })}
    </HStack>
  );
};

export default MyFace;
