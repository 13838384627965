import { CopyIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import newStockAdd from "../logic/newStockAdd";
import MyFace from "./MyFace";

function createCard(content, deletenote, user) {
  if (content.note !== "NULL") {
    return (
      <Card
        del={deletenote}
        id={content.id}
        key={content.id}
        title={content.title}
        description={content.description}
        face={content.face}
        date={content.timestamp}
        user={user}
      />
    );
  }
}

function Card(props) {
  function copyToClipboard() {
    navigator.clipboard.writeText(props.description);
    // alert("クリップボードに追加しました");
  }
  function taskDelete() {
    props.del(props.id);
  }
  /* ↓state変数を定義 */
  //引数を格納
  const [StockTitle, setStockTitle] = useState(props.title);
  const [StockDesc, setStockDesc] = useState(props.description);
  const [StockFace, setStockFace] = useState(props.face);

  //更新したカードの値を格納
  const [ChangeTitle, setChangeTitle] = useState(props.title);
  const [ChangeDesc, setChangeDesc] = useState(props.description);
  const [ChangeFace, setChangeFace] = useState(props.face);

  let date = props.date ? props.date.toDate() : new Date("2022/22/22");
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  const ModalClose = () => {
    setChangeTitle(StockTitle);
    setChangeDesc(StockDesc);
    setChangeFace(StockFace);
  };
  const ModalUpdate = () => {
    onClose();
    newStockAdd(ChangeTitle, ChangeDesc, ChangeFace, props.user, date);
    props.del(props.id);
    // setChangeTitle(StockTitle);
    // setChangeDesc(StockDesc);
    // setChangeFace(StockFace);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box
        m={["auto", "", "2"]}
        p="4"
        border="1px solid #e0e0e0"
        borderRadius="3px"
        w={["95%", "", "240px"]}
        boxShadow="0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%)"
        onClick={onOpen}
      >
        <Text fontSize="10px" color="gray">
          {year + "/" + month + "/" + day}
        </Text>
        <Heading fontSize="md" paddingBottom="3" paddingTop="2">
          {StockTitle}
        </Heading>

        <Text textAlign="left">{StockDesc}</Text>
        <Flex
          justifyContent="flex-start"
          gap="3"
          marginTop="4"
          color="gray"
          flexDirection="row-reverse"
          borderTop="1px solid #e0e0e0"
          paddingTop="3"
        >
          <Button
            size="2xl"
            variant="unstyled"
            onClick={taskDelete}
            data-position="top"
            data-tooltip="Delete"
          >
            <DeleteIcon />
          </Button>
          <Button
            size="2xl"
            variant="unstyled"
            data-position="top"
            data-tooltip="Copy To Clipboard"
            onClick={copyToClipboard}
          >
            <CopyIcon />
          </Button>
          <Spacer />
          <Text fontSize="xl">{StockFace}</Text>
        </Flex>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontSize="10px" color="gray">
              {year + "/" + month + "/" + day}
            </Text>
            <Textarea
              name="title"
              type="note"
              value={ChangeTitle}
              onChange={(e) => setChangeTitle(e.target.value)}
              variant="unstyled"
              fontWeight={"bold"}
              row={"1"}
              minHeight={"auto"}
            />
          </ModalHeader>
          <ModalCloseButton onClick={ModalClose} />
          <ModalBody minHeight={"50vh"}>
            <Textarea
              name="description"
              type="text"
              value={ChangeDesc}
              onChange={(e) => setChangeDesc(e.target.value)}
              variant="unstyled"
              minHeight={"30vh"}
            />
            <MyFace setStockFace={setChangeFace} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={ModalUpdate}>
              更新
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default createCard;
