import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { FirebaseAuth } from "./firebase/FirebaseConfig";
import logo from "./sections/peoplesLogo.png";

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const Login = () => {
  /* ↓state変数を定義 */
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  /* ↓関数「handleSubmit」を定義 */
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(FirebaseAuth, loginEmail, loginPassword);
    } catch (error) {
      alert("メールアドレスまたはパスワードが間違っています");
    }
  };

  /* ↓ログインを判定する設定 */
  const [user, setUser] = useState();

  useEffect(() => {
    onAuthStateChanged(FirebaseAuth, (currentUser) => {
      setUser(currentUser);
    });
  });

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      {user ? (
        <Navigate to={`/`} />
      ) : (
        <>
          {/* <h1>ログインページ</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <label>メールアドレス</label>
              <input
                name="email"
                type="email"
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
              />
            </div>
            <div>
              <label>パスワード</label>
              <input
                name="password"
                type="password"
                value={loginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
              />
            </div>

            <button>ログイン</button>
          </form>*/}

          <Flex minH={"90vh"} align={"center"} justify={"center"}>
            <Stack
              spacing={8}
              mx={"auto"}
              maxW={"lg"}
              minW={["98%", "", "600px"]}
              py={24}
              px={6}
            >
              <Stack align={"center"}>
                <Heading fontSize={"4xl"} textAlign={"center"}>
                  <Image src={logo} width="200px" />
                </Heading>
              </Stack>
              <form onSubmit={handleSubmit}>
                <Box rounded={"lg"} boxShadow={"lg"} p={8}>
                  <Stack spacing={4}>
                    <Text textAlign={"center"} py={"3"} fontWeight={"bold"}>
                      ログイン
                    </Text>
                    <FormControl id="email" isRequired>
                      <FormLabel>Email address</FormLabel>
                      <Input
                        name="email"
                        type="email"
                        value={loginEmail}
                        onChange={(e) => setLoginEmail(e.target.value)}
                      />
                    </FormControl>
                    <FormControl id="password" isRequired>
                      <FormLabel>Password</FormLabel>
                      <InputGroup>
                        <Input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={loginPassword}
                          onChange={(e) => setLoginPassword(e.target.value)}
                        />
                        <InputRightElement h={"full"}>
                          <Button
                            variant={"ghost"}
                            onClick={() =>
                              setShowPassword((showPassword) => !showPassword)
                            }
                          >
                            {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </FormControl>
                    <Stack spacing={10} pt={2}>
                      <Button
                        loadingText="Submitting"
                        size="lg"
                        type="submit"
                        bg={"blue.400"}
                        color={"white"}
                        my="4"
                        _hover={{
                          bg: "blue.500",
                        }}
                      >
                        ログイン
                      </Button>
                    </Stack>
                    {/* <Stack pt={6}>
                    <Text align={"center"}>
                      Already a user? <Link color={"blue.400"}>Login</Link>
                    </Text>
                  </Stack> */}
                  </Stack>
                </Box>
              </form>
            </Stack>
          </Flex>
        </>
      )}
    </>
  );
};

export default Login;
