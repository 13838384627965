import React from "react";
import logo from "./peoplesLogo.png";
import peopleIcon from "./peopleIcon.svg";
import { logout } from "../firebase/FirebaseAuthFunc";
import {
  Box,
  Flex,
  Avatar,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Image,
} from "@chakra-ui/react";
import { MoonIcon, RepeatIcon, SunIcon } from "@chakra-ui/icons";

const NavLink = ({ children }) => (
  <Link
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
    href={"#"}
  >
    {children}
  </Link>
);

const Header = (props) => {
  const { user } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <Box>
            <Image src={logo} width="150px" />
          </Box>

          <Flex alignItems={"center"}>
            <Stack direction={"row"} spacing={6}>
              <Link href="/">
                <Button>
                  <RepeatIcon />
                </Button>
              </Link>
              <Button onClick={toggleColorMode}>
                {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              </Button>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar size={"sm"} src={peopleIcon} />
                </MenuButton>
                <MenuList alignItems={"center"}>
                  <br />
                  <Center>
                    <Avatar size={"2xl"} src={peopleIcon} />
                  </Center>
                  <br />
                  <Center>
                    <p>{user?.name}</p>
                  </Center>
                  <br />
                  <MenuDivider />
                  {/* <MenuItem>Account Settings</MenuItem> */}
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

// const Header = (props) => {
//   const { user } = props;

//   return (
//     <div>
//       <div className="navbar-fixed white blue-text text-darken-2">
//         <nav>
//           <div className="white blue-text text-darken-2 nav-wrapper">
//             <a href="#!" className="blue-text text-darken-2 brand-logo logo">
//               <img
//                 src={logo}
//                 alt="people's"
//                 width="150px"
//                 style={{ paddingTop: "16px" }}
//               />
//               {/* <span className="hero-text">Stock</span> */}
//             </a>
//             <ul className="white blue-text text-darken-2 right hide-on-med-and-down">
//               <li>
//                 <a className="blue-text text-darken-2" href="/">
//                   <i className="material-icons">refresh</i>
//                 </a>
//               </li>
//               <li>
//                 <span>{user?.name}</span>
//               </li>
//             </ul>
//           </div>
//         </nav>
//       </div>
//     </div>
//   );
// };

export default Header;
