import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { FirestoreDB } from "./firebase/FirebaseConfig";

const Test = () => {
  // データをDBから取得
  const [stocks, setStocks] = useState();
  useEffect(() => {
    //データベースからデータを取得する
    const postDeta = collection(FirestoreDB, "stocks");
    getDocs(postDeta).then((snapShot) => {
      // console.log(snapShot.docs.map((doc) => ({ ...doc.data() })));
      setStocks(snapShot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
  }, []);
  return (
    <div>
      {/* <div>test</div> */}
      {/* {array.map((stock) => (
        // <h2>{stock.title}</h2>
        <h1 key={stock}>{stock}</h1>
      ))} */}
    </div>
  );
};

export default Test;
